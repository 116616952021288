import _ from 'lodash'
import {type Dependent, type TaxReturn, type TaxReturnFlags} from '#app/model/new-responses.ts'

export function formatSIN(inputValue: string) {
	let value = inputValue.replace(/\D/g, ''); // Remove non-digits
	value = value.slice(0, 9); // Limit to 9 digits

	if (value.length > 6) {
		return `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6)}`;
	} else if (value.length > 3) {
		return `${value.slice(0, 3)}-${value.slice(3)}`;
	}
	return value;
}

export const isStepsCompleted = (flags: TaxReturnFlags | undefined): boolean => {
	if (!flags) return false;

	const isBothStepsVerified = !!flags.isSignatureStepVerified && !!flags.isPaidStepVerified;

	if (flags.hasUsReturn && flags.hasCanadianReturn) {
		return isBothStepsVerified;
	}

	if (flags.hasUsReturn) {
		return !!flags.isPaidStepVerified;
	}

	if (flags.hasCanadianReturn) {
		return isBothStepsVerified;
	}

	return false; // Optional but adds clarity
};

export function findDependencyDifference(list1: Dependent[], list2: Dependent[]): Dependent[] {
	const idsInList2 = new Set(list2.map(item => item.id));
	return list1.filter(item => !idsInList2.has(item.id));
}

export function convertOnToBoolean(payload: any) {
	return Object.keys(payload).reduce(
		(acc, key) => {
			acc[key] = payload[key] === 'on' ? true : payload[key]
			return acc
		},
		{} as Record<string, unknown>,
	)
}

export function toTitleCase(inputString?: string): string {
	if (!inputString) {
		return ''
	}
	return inputString?.toLowerCase().replace(/(?:^|\s)\w/g, match => match.toUpperCase()) ?? ''
}

export const getCurrentTaxYear = (): string => {
	const currentDate = new Date();
	return (currentDate.getFullYear() - 1).toString();
}


export const textToAnchor = (text: string) => {
	// Regular expression to match URLs
	var urlRegex = /(https?:\/\/[^\s]+)/g

	// Replace URLs in the text with anchor elements
	// Return the modified text with anchor elements
	return text.replace(urlRegex, function (url) {
		return (
			'<a href="' +
			url +
			'" target="_blank" rel="noreferrer" style="color: #3b82f6; font-weight: bold; text-decoration: underline;">' +
			url +
			'</a>'
		)
	})
}

export const mapTaxReturnToLocationKeywords = (taxReturn: TaxReturn | null): string[] => {
	const out: string[] = []
	if (taxReturn) {
		if (taxReturn.residency && 'CANADA' === taxReturn.residency) {
			out.push('in canada. Give specific related CRA links if applicable.')
		}
		if (taxReturn.residency && 'USA' === taxReturn.residency) {
			out.push('in united states. Give specific related IRS links if applicable.')
		}
		if (taxReturn.province) {
			out.push('in ' + taxReturn.province)
		}
	}
	return out
}

export const mapTaxReturnToProfileKeywords = (taxReturn: TaxReturn | null): string[] => {
	const out: string[] = []
	if (taxReturn) {
		if (taxReturn.maritalStatus && !_.isEmpty(taxReturn.maritalStatus)) {
			out.push(taxReturn.maritalStatus)
		}
		if (taxReturn.dependencies && taxReturn.dependencies.length > 0) {
			out.push('has kids')
		}
		if (taxReturn.rentals && taxReturn.rentals.length > 0) {
			out.push('has rental')
		}
		if (taxReturn.occupation && !_.isEmpty(taxReturn.occupation)) {
			out.push(taxReturn.occupation)
		}
		if (taxReturn.birthDate && !_.isEmpty(taxReturn.birthDate)) {
			out.push(`is ${calculateAge(taxReturn.birthDate)} years old`)
		}
	}
	return out
}

export const calculateAge = (birthDate: Date): number => {
	const currentDate = new Date()
	const _birthDate = new Date(birthDate)

	const ageDiff = currentDate.getFullYear() - _birthDate.getFullYear()
	const isSameMonthAndDay =
		currentDate.getMonth() < _birthDate.getMonth() ||
		(currentDate.getMonth() === _birthDate.getMonth() && currentDate.getDate() < _birthDate.getDate())

	return isSameMonthAndDay ? ageDiff - 1 : ageDiff
}

export const convertNumberToUnitAmountDecimal = (amount: number): number => {
	return Number(amount.toFixed(2).replace('.', ''))
}

export function dataURLtoFile(dataURL: any, filename: any) {
	// Extract base64 data from data URL
	const base64Data = dataURL.split(';base64,').pop()

	// Convert base64 to binary
	const binaryData = atob(base64Data)

	// Convert binary data to an array buffer
	const arrayBuffer = new ArrayBuffer(binaryData.length)
	const uint8Array = new Uint8Array(arrayBuffer)
	for (let i = 0; i < binaryData.length; i++) {
		uint8Array[i] = binaryData.charCodeAt(i)
	}

	// Create a Blob from the array buffer
	const blob = new Blob([uint8Array], { type: 'image/png' })

	// Create a File from the Blob
	return new File([blob], filename, { type: 'image/png' })
}

export const getCurrentUrl = (): string => {
	const protocol = window.location.protocol
	const host = window.location.hostname
	const port = window.location.port

	// Construct the URL with protocol, host, and port
	let url = protocol + '//' + host
	if (port) {
		url += ':' + port
	}
	return url
}

const CURRENCY_FORMATTER_CAD = new Intl.NumberFormat('en-CA', {
	style: 'currency',
	currency: 'CAD',
})

export const toCurrencyFromNumber = (amount?: number) => {
	if (!amount) {
		return CURRENCY_FORMATTER_CAD.format(Number('0.00'))
	}
	return CURRENCY_FORMATTER_CAD.format(amount)
}

export const toCurrency = (amount?: string): string => {
	if (!amount) {
		return CURRENCY_FORMATTER_CAD.format(Number('0.00'))
	}
	return CURRENCY_FORMATTER_CAD.format(Number(amount))
}

export const isTaxReturnEfiled = (state: string): boolean => {
	return 'EFILED' == state
}

export const isTaxReturnNOTInNewOrInProgressState = (state: string): boolean => {
	return 'NEW' != state && 'IN_PROGRESS' != state
}

export const toHumanReadableState = (status: string, flags: TaxReturnFlags): string => {
	if ('NEW' == status) {
		return 'NEW'
	}
	if ('IN_PROGRESS' == status) {
		return 'IN PROGRESS'
	}
	if ('READY' == status) {
		return 'COMPLETE THE STEPS'
	}
	if ('SIGNED' == status) {
		return 'PAY TAX CONSULTANT'
	}
	if ('WAITING_FOR_PAYMENT' == status) {
		return 'WAITING FOR ETRANSFER'
	}
	if ('PAID' == status && flags.hasUsReturn && !flags.hasCanadianReturn) {
		return 'COMPLETED'
	}
	if ('PAID' == status) {
		return 'SIGN YOUR T183'
	}
	if ('SIGNED_AND_PAID' == status) {
		return 'READY TO EFILE'
	}
	if ('EFILED' == status) {
		return 'EFILED'
	}
	return ''
}

export const getPastYearsAsOptions = () => {
	const options = []
	const currentYear = new Date().getFullYear()
	for (let i = currentYear - 1; i >= currentYear - 10; i--) {
		options.push({
			value: i,
			label: i,
		})
	}
	return options
}

export const decimalToPercent = (decimal: number) => {
	// Multiply the decimal by 100 to get the percentage

	// Check if the result is a whole number
	if (decimal % 1 === 0) {
		// If it's a whole number, return without decimals
		return decimal.toFixed(0) + '%'
	} else {
		// If it's not a whole number, return with decimals
		return decimal.toFixed(1) + '%'
	}
}

export const calculatePercent = (cost: number, paymentAdjustment: number): string => {
	return (cost + cost * (paymentAdjustment / 100)).toFixed(2)
}
